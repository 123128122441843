<template>
  <div class="home-container">
    <div class="recommend-box">
      <div class="tips">线上自动审批 | 两小时内放款</div>
      <div class="pro-box">
        <div class="pro-wrap">
          <div class="logo">
            <img :src="recommend.logoUrl" />
          </div>
          <div class="pro-info">
            <span>{{ recommend.productName }}</span>
            <span class="rate">日利率低至{{ recommend.interestRate }}%</span>
          </div>
        </div>
        <div class="btn-wrap">
          <van-button class="btn-apply"
            @click="go2Apply(recommend.productCode, recommend.productName, recommend.productUrl)">立即申请</van-button>
        </div>
      </div>
      <div class="pro-wrap">
        <div>
          <div class="amount-range">{{ recommend.minPrincipal }} - {{ recommend.maxPrincipal }}</div>
          <div class="product-tips">额度范围</div>
        </div>
        <div>
          <span class="rate">{{ recommend.minPeriods }} - {{ recommend.maxPeriods }}</span>
          <div class="product-tips">借款期限(月)</div>
        </div>
      </div>
    </div>
    <div class="swiper-wrap">
      <van-swipe vertical autoplay="1000" :show-indicators="false" style="height: 32px;">
        <van-swipe-item v-for="item in swiperList" :key="item">
          <div class="swiper-item" style="height: 32px;">
            <van-icon name="volume-o" color="#517CDD" />
            <span class="swiper-text">{{ item }}</span>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <!-- 贷超列表 -->
    <div class="hot-title">
      <div>今日爆款</div>
      <div class="tips">每天9:00准点更新</div>
    </div>
    <div class="prod-list">
      <ProductItem v-for="product in productList" :key="product.productCode" :product="product" />
    </div>
  </div>
</template>

<script>
import ProductItem from '@/components/ProductItem.vue'
import { getProductList, getRecommend, recordClick } from '@/api/user'

export default {
  name: 'Home',
  components: {
    ProductItem
  },
  props: {
    source: {
      type: String,
      default: () => {
        return localStorage.getItem('source') || 'unknow'
      }
    }
  },
  data() {
    return {
      list: [],
      swiperList: [
        '134****3364先生/女士申请6000元放款成功',
        '153****2167先生/女士申请6000元放款成功',
        '137****1193先生/男士申请5000元放款成功',
      ],
      loading: false,
      finished: false,
      recommend: {
        applyNum: 0,
        borrowDuration: 0,
        interestRate: 0,
        logoUrl: '',
        maxPeriods: 0,
        maxPrincipal: 0,
        minPeriods: 0,
        minPrincipal: 0,
        productCode: '',
        productName: '',
        productUrl: ''
      },
      productList: [],
      isLoading: false
    }
  },
  async mounted() {
    await this.getProductList()

  },
  methods: {
    async getRecommend() {
      const res = await getRecommend()
      this.recommend = res.data
    },
    async getProductList() {
      const res = await getProductList()
      const productList = res.data
      if (productList.length > 0) {
        // 第一个产品放到recommend
        if (productList[0]) {
          this.recommend = productList[0];
        }
        // 剩余产品放到productList
        if (productList.length > 1) {
          this.productList = productList.slice(1);
        }
      }
    },
    async go2Apply(code, name, url) {
      await recordClick({ productCode: code })
      window.location.href = url
    }
  }
}
</script>

<style scoped lang="less">
.home-container {
  min-height: calc(100vh - 100px);
  background-color: #f2f2f2;

  .recommend-box {
    background-image: url('~@/assets/bg.png');
    background-size: cover;
    background-position: center;
    padding: 20px;
    margin: 20px 20px;
    border-radius: 12px;

    .tips {
      font-size: 24px;
      color: #fff;
      margin-left: 20px;
    }

    .pro-box {
      border-radius: 12px;
      padding: 20px;
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .pro-wrap {
        display: flex;

        .logo {
          width: 80px;
          height: 80px;
          margin-right: 15px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            border-radius: 12px;
          }
        }

        .pro-info {
          display: flex;
          flex-direction: column;

          span {
            font-size: 32px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 8px;
          }

          .rate {
            font-size: 26px;
            color: #fff;
          }
        }
      }

      .btn-wrap {


        .btn-apply {
          padding: 0 40px;
          background: #fff;
          color: #517CDD;
          border: none;
          border-radius: 50px;

          font-size: 28px;
          font-weight: bold;
        }
      }
    }

    .pro-wrap {
      display: flex;
      justify-content: space-around;
      text-align: center;

      .amount-range {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 8px;
      }

      .rate {
        font-size: 36px;
        font-weight: bold;
        color: #fff;
        margin-bottom: 8px;
      }

      .product-tips {
        font-size: 24px;
        color: #fff;
      }
    }
  }

  .hot-title {
    font-size: 34px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333;
    margin: 20px 20px 5px;
    display: flex;
    align-items: center;

    // justify-content: space-between;
    .tips {
      font-size: 24px;
      color: #517CDD;
      margin-left: 20px;
    }
  }

  .prod-list {
    min-height: 500px;
    margin-top: 15px;
    padding-bottom: 100px;
  }

  .swiper-wrap {
    margin: 0 20px;
    background: #fff;
    border-radius: 8px;


    /deep/ .swiper-item {
      display: flex;
      align-items: center;
      justify-content: center;

      .van-icon {
        margin-right: 8px;
        font-size: 32px;
        flex-shrink: 0;
      }

      .swiper-text {
        font-size: 32px;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .float-service {
    position: fixed;
    right: 16px;
    bottom: 120px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
    background: linear-gradient(135deg, #1989fa, #0056b3);
    border-radius: 24px;
    box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    transition: transform 0.3s ease;

    &:active {
      transform: scale(0.95);
    }

    .service-icon {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;

      .van-icon {
        font-size: 20px;
        color: #fff;
      }
    }

    .service-text {
      font-size: 12px;
      color: #fff;
      white-space: nowrap;
    }

    // 添加呼吸灯效果
    animation: breath 2s ease-in-out infinite;
  }

  @keyframes breath {
    0% {
      transform: scale(1);
      box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    }

    50% {
      transform: scale(1.05);
      box-shadow: 0 4px 16px rgba(25, 137, 250, 0.4);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 4px 12px rgba(25, 137, 250, 0.3);
    }
  }
}
</style>
