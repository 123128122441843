<template>
  <div class="product-box">
    <div class="product">
      <img :src="product.logoUrl" />
      <span>{{ product.productName }}</span>
    </div>
    <div class="desc">
      <div class="t1">
        <div class="money-range">{{ product.minPrincipal }} - {{ product.maxPrincipal }}</div>
        <div class="label">可贷额度（元）</div>
      </div>
      <div class="t2">
        <div class="interest">参考日利息（%）：{{ product.interestRate }}</div>
        <div class="time">放款时间（分钟）：{{ product.loanTime }}</div>
      </div>
      <div class="t3">
        <van-button class="btn-apply" @click="go2Apply(product)">一键申请</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { recordClick } from '@/api/user'
export default {
  name: 'ProductItem',
  props: {
    product: {
      type: Object,
      default: () => ({
        productCode: '',
        productName: '',
        productUrl: '',
        logoUrl: '',
        minPrincipal: '',
        maxPrincipal: '',
        interestRate: '',
        loanTime: ''
      })
    }
  },
  methods: {
    async go2Apply({ productCode, productName, productUrl }) {
      await recordClick({ productCode: productCode })
      window.location.href = productUrl
    }
  }
}
</script>

<style scoped lang="less">
.product-box {
  background: #ffffff;
  border-radius: 20px;
  margin: 10px 20px;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;

  .product {
    display: flex;
    align-items: center;

    img {
      width: 100px;
      height: 100px;
      margin-right: 10px;
      border-radius: 5px;
    }

    span {
      font-size: .43rem;
      font-weight: bold;
      color: #333333;
    }
  }

  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .t1,
    .t2,
    .t3 {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .money-range {
      font-size: .30rem;
      color: #007aff;
    }

    .label {
      font-size: .30rem;
      color: #999999;
    }

    .interest,
    .time {
      font-size: .30rem;
      color: #999999;
    }

    .btn-apply {
      background: #007aff;
      color: #ffffff;
      border-radius: 30rem;
    }

    /deep/ .van-button__content {
      padding: 0px 10px;
    }
  }
}
</style>
